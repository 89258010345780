import {Component} from "react";
import BootstrapTable from "react-bootstrap-table-next"
import {Proposals} from "./Proposals";
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {Button, Row, Col, Container} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proposals: []
        }
        this.applyFilter = this.applyFilter.bind(this);
    }

    componentDidMount() {
        Proposals({}).then((proposals) => {
            this.setState({proposals: proposals})
        })
    }

    async applyFilter(e) {
        e.preventDefault()
        const form = e.currentTarget
        const filter = {}
        if (form[0].value !== "") {
            filter.provider_id = form[0].value
        }
        if (form[1].value !== "") {
            filter.quality_min = form[1].value
        }
        if (form[2].value !== "") {
            filter.location_country = form[2].value
        }
        if (form[3].value !== "") {
            filter.ip_type = form[3].value
        }
        if (form[4].value !== "") {
            filter.service_type = form[4].value
        }
        Proposals(filter).then((proposals) => {
            this.setState({proposals: proposals})
        })
    }

    render() {
        return (
            <Container style={styles.container}>
                <Form style={styles.filterForm} onSubmit={this.applyFilter}>
                    <Row>
                        <Col>
                            <Form.Control placeholder="Provider ID" />
                        </Col>
                        <Col>
                            <Form.Control placeholder="Min quality" />
                        </Col>
                        <Col>
                            <Form.Select aria-label="Default select example">
                                <option value="" > Country</option>
                                <option value="AD">	🇦🇩 Andorra </option>
                                <option value="AE">	🇦🇪 United Arab Emirates </option>
                                <option value="AF">	🇦🇫 Afghanistan </option>
                                <option value="AG">	🇦🇬 Antigua and Barbuda </option>
                                <option value="AI">	🇦🇮 Anguilla </option>
                                <option value="AL">	🇦🇱 Albania </option>
                                <option value="AM">	🇦🇲 Armenia </option>
                                <option value="AO">	🇦🇴 Angola </option>
                                <option value="AQ">	🇦🇶 Antarctica </option>
                                <option value="AR">	🇦🇷 Argentina </option>
                                <option value="AS">	🇦🇸 American Samoa </option>
                                <option value="AT">	🇦🇹 Austria </option>
                                <option value="AU">	🇦🇺 Australia </option>
                                <option value="AW">	🇦🇼 Aruba </option>
                                <option value="AX">	🇦🇽 Åland Islands </option>
                                <option value="AZ">	🇦🇿 Azerbaijan </option>
                                <option value="BA">	🇧🇦 Bosnia and Herzegovina </option>
                                <option value="BB">	🇧🇧 Barbados </option>
                                <option value="BD">	🇧🇩 Bangladesh </option>
                                <option value="BE">	🇧🇪 Belgium </option>
                                <option value="BF">	🇧🇫 Burkina Faso </option>
                                <option value="BG">	🇧🇬 Bulgaria </option>
                                <option value="BH">	🇧🇭 Bahrain </option>
                                <option value="BI">	🇧🇮 Burundi </option>
                                <option value="BJ">	🇧🇯 Benin </option>
                                <option value="BL">	🇧🇱 Saint Barthélemy </option>
                                <option value="BM">	🇧🇲 Bermuda </option>
                                <option value="BN">	🇧🇳 Brunei Darussalam </option>
                                <option value="BO">	🇧🇴 Bolivia </option>
                                <option value="BQ">	🇧🇶 Bonaire, Sint Eustatius and Saba </option>
                                <option value="BR">	🇧🇷 Brazil </option>
                                <option value="BS">	🇧🇸 Bahamas </option>
                                <option value="BT">	🇧🇹 Bhutan </option>
                                <option value="BV">	🇧🇻 Bouvet Island </option>
                                <option value="BW">	🇧🇼 Botswana </option>
                                <option value="BY">	🇧🇾 Belarus </option>
                                <option value="BZ">	🇧🇿 Belize </option>
                                <option value="CA">	🇨🇦 Canada </option>
                                <option value="CC">	🇨🇨 Cocos (Keeling) Islands </option>
                                <option value="CD">	🇨🇩 Congo </option>
                                <option value="CF">	🇨🇫 Central African Republic </option>
                                <option value="CG">	🇨🇬 Congo </option>
                                <option value="CH">	🇨🇭 Switzerland </option>
                                <option value="CI">	🇨🇮 Côte D'Ivoire </option>
                                <option value="CK">	🇨🇰 Cook Islands </option>
                                <option value="CL">	🇨🇱 Chile </option>
                                <option value="CM">	🇨🇲 Cameroon </option>
                                <option value="CN">	🇨🇳 China </option>
                                <option value="CO">	🇨🇴 Colombia </option>
                                <option value="CR">	🇨🇷 Costa Rica </option>
                                <option value="CU">	🇨🇺 Cuba </option>
                                <option value="CV">	🇨🇻 Cape Verde </option>
                                <option value="CW">	🇨🇼 Curaçao </option>
                                <option value="CX">	🇨🇽 Christmas Island </option>
                                <option value="CY">	🇨🇾 Cyprus </option>
                                <option value="CZ">	🇨🇿 Czech Republic </option>
                                <option value="DE">	🇩🇪 Germany </option>
                                <option value="DJ">	🇩🇯 Djibouti </option>
                                <option value="DK">	🇩🇰 Denmark </option>
                                <option value="DM">	🇩🇲 Dominica </option>
                                <option value="DO">	🇩🇴 Dominican Republic </option>
                                <option value="DZ">	🇩🇿 Algeria </option>
                                <option value="EC">	🇪🇨 Ecuador </option>
                                <option value="EE">	🇪🇪 Estonia </option>
                                <option value="EG">	🇪🇬 Egypt </option>
                                <option value="EH">	🇪🇭 Western Sahara </option>
                                <option value="ER">	🇪🇷 Eritrea </option>
                                <option value="ES">	🇪🇸 Spain </option>
                                <option value="ET">	🇪🇹 Ethiopia </option>
                                <option value="FI">	🇫🇮 Finland </option>
                                <option value="FJ">	🇫🇯 Fiji </option>
                                <option value="FK">	🇫🇰 Falkland Islands (Malvinas) </option>
                                <option value="FM">	🇫🇲 Micronesia </option>
                                <option value="FO">	🇫🇴 Faroe Islands </option>
                                <option value="FR">	🇫🇷 France </option>
                                <option value="GA">	🇬🇦 Gabon </option>
                                <option value="GB">	🇬🇧 United Kingdom </option>
                                <option value="GD">	🇬🇩 Grenada </option>
                                <option value="GE">	🇬🇪 Georgia </option>
                                <option value="GF">	🇬🇫 French Guiana </option>
                                <option value="GG">	🇬🇬 Guernsey </option>
                                <option value="GH">	🇬🇭 Ghana </option>
                                <option value="GI">	🇬🇮 Gibraltar </option>
                                <option value="GL">	🇬🇱 Greenland </option>
                                <option value="GM">	🇬🇲 Gambia </option>
                                <option value="GN">	🇬🇳 Guinea </option>
                                <option value="GP">	🇬🇵 Guadeloupe </option>
                                <option value="GQ">	🇬🇶 Equatorial Guinea </option>
                                <option value="GR">	🇬🇷 Greece </option>
                                <option value="GS">	🇬🇸 South Georgia </option>
                                <option value="GT">	🇬🇹 Guatemala </option>
                                <option value="GU">	🇬🇺 Guam </option>
                                <option value="GW">	🇬🇼 Guinea-Bissau </option>
                                <option value="GY">	🇬🇾 Guyana </option>
                                <option value="HK">	🇭🇰 Hong Kong </option>
                                <option value="HM">	🇭🇲 Heard Island and Mcdonald Islands </option>
                                <option value="HN">	🇭🇳 Honduras </option>
                                <option value="HR">	🇭🇷 Croatia </option>
                                <option value="HT">	🇭🇹 Haiti </option>
                                <option value="HU">	🇭🇺 Hungary </option>
                                <option value="ID">	🇮🇩 Indonesia </option>
                                <option value="IE">	🇮🇪 Ireland </option>
                                <option value="IL">	🇮🇱 Israel </option>
                                <option value="IM">	🇮🇲 Isle of Man </option>
                                <option value="IN">	🇮🇳 India </option>
                                <option value="IO">	🇮🇴 British Indian Ocean Territory </option>
                                <option value="IQ">	🇮🇶 Iraq </option>
                                <option value="IR">	🇮🇷 Iran </option>
                                <option value="IS">	🇮🇸 Iceland </option>
                                <option value="IT">	🇮🇹 Italy </option>
                                <option value="JE">	🇯🇪 Jersey </option>
                                <option value="JM">	🇯🇲 Jamaica </option>
                                <option value="JO">	🇯🇴 Jordan </option>
                                <option value="JP">	🇯🇵 Japan </option>
                                <option value="KE">	🇰🇪 Kenya </option>
                                <option value="KG">	🇰🇬 Kyrgyzstan </option>
                                <option value="KH">	🇰🇭 Cambodia </option>
                                <option value="KI">	🇰🇮 Kiribati </option>
                                <option value="KM">	🇰🇲 Comoros </option>
                                <option value="KN">	🇰🇳 Saint Kitts and Nevis </option>
                                <option value="KP">	🇰🇵 North Korea </option>
                                <option value="KR">	🇰🇷 South Korea </option>
                                <option value="KW">	🇰🇼 Kuwait </option>
                                <option value="KY">	🇰🇾 Cayman Islands </option>
                                <option value="KZ">	🇰🇿 Kazakhstan </option>
                                <option value="LA">	🇱🇦 Lao People's Democratic Republic </option>
                                <option value="LB">	🇱🇧 Lebanon </option>
                                <option value="LC">	🇱🇨 Saint Lucia </option>
                                <option value="LI">	🇱🇮 Liechtenstein </option>
                                <option value="LK">	🇱🇰 Sri Lanka </option>
                                <option value="LR">	🇱🇷 Liberia </option>
                                <option value="LS">	🇱🇸 Lesotho </option>
                                <option value="LT">	🇱🇹 Lithuania </option>
                                <option value="LU">	🇱🇺 Luxembourg </option>
                                <option value="LV">	🇱🇻 Latvia </option>
                                <option value="LY">	🇱🇾 Libya </option>
                                <option value="MA">	🇲🇦 Morocco </option>
                                <option value="MC">	🇲🇨 Monaco </option>
                                <option value="MD">	🇲🇩 Moldova </option>
                                <option value="ME">	🇲🇪 Montenegro </option>
                                <option value="MF">	🇲🇫 Saint Martin (French Part) </option>
                                <option value="MG">	🇲🇬 Madagascar </option>
                                <option value="MH">	🇲🇭 Marshall Islands </option>
                                <option value="MK">	🇲🇰 Macedonia </option>
                                <option value="ML">	🇲🇱 Mali </option>
                                <option value="MM">	🇲🇲 Myanmar </option>
                                <option value="MN">	🇲🇳 Mongolia </option>
                                <option value="MO">	🇲🇴 Macao </option>
                                <option value="MP">	🇲🇵 Northern Mariana Islands </option>
                                <option value="MQ">	🇲🇶 Martinique </option>
                                <option value="MR">	🇲🇷 Mauritania </option>
                                <option value="MS">	🇲🇸 Montserrat </option>
                                <option value="MT">	🇲🇹 Malta </option>
                                <option value="MU">	🇲🇺 Mauritius </option>
                                <option value="MV">	🇲🇻 Maldives </option>
                                <option value="MW">	🇲🇼 Malawi </option>
                                <option value="MX">	🇲🇽 Mexico </option>
                                <option value="MY">	🇲🇾 Malaysia </option>
                                <option value="MZ">	🇲🇿 Mozambique </option>
                                <option value="NA">	🇳🇦 Namibia </option>
                                <option value="NC">	🇳🇨 New Caledonia </option>
                                <option value="NE">	🇳🇪 Niger </option>
                                <option value="NF">	🇳🇫 Norfolk Island </option>
                                <option value="NG">	🇳🇬 Nigeria </option>
                                <option value="NI">	🇳🇮 Nicaragua </option>
                                <option value="NL">	🇳🇱 Netherlands </option>
                                <option value="NO">	🇳🇴 Norway </option>
                                <option value="NP">	🇳🇵 Nepal </option>
                                <option value="NR">	🇳🇷 Nauru </option>
                                <option value="NU">	🇳🇺 Niue </option>
                                <option value="NZ">	🇳🇿 New Zealand </option>
                                <option value="OM">	🇴🇲 Oman </option>
                                <option value="PA">	🇵🇦 Panama </option>
                                <option value="PE">	🇵🇪 Peru </option>
                                <option value="PF">	🇵🇫 French Polynesia </option>
                                <option value="PG">	🇵🇬 Papua New Guinea </option>
                                <option value="PH">	🇵🇭 Philippines </option>
                                <option value="PK">	🇵🇰 Pakistan </option>
                                <option value="PL">	🇵🇱 Poland </option>
                                <option value="PM">	🇵🇲 Saint Pierre and Miquelon </option>
                                <option value="PN">	🇵🇳 Pitcairn </option>
                                <option value="PR">	🇵🇷 Puerto Rico </option>
                                <option value="PS">	🇵🇸 Palestinian Territory </option>
                                <option value="PT">	🇵🇹 Portugal </option>
                                <option value="PW">	🇵🇼 Palau </option>
                                <option value="PY">	🇵🇾 Paraguay </option>
                                <option value="QA">	🇶🇦 Qatar </option>
                                <option value="RE">	🇷🇪 Réunion </option>
                                <option value="RO">	🇷🇴 Romania </option>
                                <option value="RS">	🇷🇸 Serbia </option>
                                <option value="RU">	🇷🇺 Russia </option>
                                <option value="RW">	🇷🇼 Rwanda </option>
                                <option value="SA">	🇸🇦 Saudi Arabia </option>
                                <option value="SB">	🇸🇧 Solomon Islands </option>
                                <option value="SC">	🇸🇨 Seychelles </option>
                                <option value="SD">	🇸🇩 Sudan </option>
                                <option value="SE">	🇸🇪 Sweden </option>
                                <option value="SG">	🇸🇬 Singapore </option>
                                <option value="SH">	🇸🇭 Saint Helena, Ascension and Tristan Da Cunha </option>
                                <option value="SI">	🇸🇮 Slovenia </option>
                                <option value="SJ">	🇸🇯 Svalbard and Jan Mayen </option>
                                <option value="SK">	🇸🇰 Slovakia </option>
                                <option value="SL">	🇸🇱 Sierra Leone </option>
                                <option value="SM">	🇸🇲 San Marino </option>
                                <option value="SN">	🇸🇳 Senegal </option>
                                <option value="SO">	🇸🇴 Somalia </option>
                                <option value="SR">	🇸🇷 Suriname </option>
                                <option value="SS">	🇸🇸 South Sudan </option>
                                <option value="ST">	🇸🇹 Sao Tome and Principe </option>
                                <option value="SV">	🇸🇻 El Salvador </option>
                                <option value="SX">	🇸🇽 Sint Maarten (Dutch Part) </option>
                                <option value="SY">	🇸🇾 Syrian Arab Republic </option>
                                <option value="SZ">	🇸🇿 Swaziland </option>
                                <option value="TC">	🇹🇨 Turks and Caicos Islands </option>
                                <option value="TD">	🇹🇩 Chad </option>
                                <option value="TF">	🇹🇫 French Southern Territories </option>
                                <option value="TG">	🇹🇬 Togo </option>
                                <option value="TH">	🇹🇭 Thailand </option>
                                <option value="TJ">	🇹🇯 Tajikistan </option>
                                <option value="TK">	🇹🇰 Tokelau </option>
                                <option value="TL">	🇹🇱 Timor-Leste </option>
                                <option value="TM">	🇹🇲 Turkmenistan </option>
                                <option value="TN">	🇹🇳 Tunisia </option>
                                <option value="TO">	🇹🇴 Tonga </option>
                                <option value="TR">	🇹🇷 Turkey </option>
                                <option value="TT">	🇹🇹 Trinidad and Tobago </option>
                                <option value="TV">	🇹🇻 Tuvalu </option>
                                <option value="TW">	🇹🇼 Taiwan </option>
                                <option value="TZ">	🇹🇿 Tanzania </option>
                                <option value="UA">	🇺🇦 Ukraine </option>
                                <option value="UG">	🇺🇬 Uganda </option>
                                <option value="UM">	🇺🇲 United States Minor Outlying Islands </option>
                                <option value="US">	🇺🇸 United States </option>
                                <option value="UY">	🇺🇾 Uruguay </option>
                                <option value="UZ">	🇺🇿 Uzbekistan </option>
                                <option value="VA">	🇻🇦 Vatican City </option>
                                <option value="VC">	🇻🇨 Saint Vincent and The Grenadines </option>
                                <option value="VE">	🇻🇪 Venezuela </option>
                                <option value="VG">	🇻🇬 Virgin Islands, British </option>
                                <option value="VI">	🇻🇮 Virgin Islands, U.S. </option>
                                <option value="VN">	🇻🇳 Viet Nam </option>
                                <option value="VU">	🇻🇺 Vanuatu </option>
                                <option value="WF">	🇼🇫 Wallis and Futuna </option>
                                <option value="WS">	🇼🇸 Samoa </option>
                                <option value="YE">	🇾🇪 Yemen </option>
                                <option value="YT">	🇾🇹 Mayotte </option>
                                <option value="ZA">	🇿🇦 South Africa </option>
                                <option value="ZM">	🇿🇲 Zambia </option>
                                <option value="ZW">	🇿🇼 Zimbabwe </option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Select aria-label="Default select example">
                                <option value="" >IP Type</option>
                                <option value="residential">Residential</option>
                                <option value="hosting">Hosting</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Select aria-label="Default select example">
                                <option value="" >Service Type</option>
                                <option value="scraping">Scraping</option>
                                <option value="data_transfer">Data Transfer</option>
                                <option value="wireguard">Public</option>
                                <option value="openvpn">Openvpn</option>
                                <option value="noop">Noop</option>
                                <option value="dvpn">Dvpn</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Button variant="primary" type="submit">
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <div>Total: {this.state.proposals?.length}</div>
                <BootstrapTable
                    keyField='provider_id'
                    data={this.state.proposals}
                    columns={proposalColumns}
                    expandRow={expandRow}
                    pagination={ pagination }
                    striped
                    hover
                    condensed
                />
            </Container>
        )
    }
}

const proposalColumns = [
    {
        dataField: 'provider_id',
        text: "ID",
        formatter: function (cell, row) {
            return (
                <div>
                    <a href="#" onClick={(e) => {e.preventDefault(); navigator.clipboard.writeText(row.provider_id)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                            <path
                                d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
                            <path
                                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
                        </svg>
                    </a>
                    &nbsp;
                    {row.provider_id}
                </div>
            )
        }

    },
    {
        text: "Quality",
        formatter: function (cell, row) {
            var quality = '⬛'
            if (row.quality.quality === 0) {
                quality = '🟥'
            } else if (row.quality.quality < 1) {
                quality = '🟥'
            } else if (row.quality.quality < 1.5) {
                quality = '🟧'
            } else if (row.quality.quality < 2) {
                quality = '🟨'
            } else {
                quality = '🟩'
            }
            return (
                <div>
                    {quality}
                </div>
            )
        },
    },
    {
        text: "Country",
        formatter: function (cell, row) {
            return ( `${getUnicodeFlagIcon(row.location.country)} ${row.location.city} (${row.location.isp})`)
        }
    },
    {
        text: "IP Type",
        formatter: function (cell, row) {
            const type = row.location.ip_type
            if (type === 'residential') {
                return (<span className="badge text-bg-success">Residential</span>)
            } else if (type === 'hosting') {
                return (<span className="badge text-bg-warning">Hosting</span>)
            }

            return (<span className="badge text-bg-dark">{row.location.ip_type}</span>)
        }
    },
    {
        text: "VPN Type",
        formatter: function (cell, row) {
            return row.services.map((type) => {
                let output = ''
                switch (type) {
                    case 'wireguard': output = 'Public'; break
                    case 'noop': output = 'No-op'; break
                    case 'openvpn': output = 'OpenVPN'; break
                    case 'scraping': output = 'Scraping'; break
                    case 'data_transfer': output = 'Data Transfer'; break
                    default: output = type
                }
                return (<span className="badge text-bg-dark">{output}</span>)
            })
        }
    },

]

const expandRow = {
    renderer: row => (
        <div>
            <p> Quality: {Number(parseFloat(row.quality.quality).toFixed(2))}/3 </p>
            <p> Latency: {Number(parseFloat(row.quality.latency).toFixed(0))}ms </p>
            <p> Bandwidth: {Number(parseFloat(row.quality.bandwidth).toFixed(2))}Mbps </p>
            <p> Uptime: {Number(parseFloat(row.quality.uptime).toFixed(2))}/24 </p>
        </div>
    ),
        showExpandColumn: true,
        expandByColumnOnly: true
};

const pagination = paginationFactory({
    sizePerPage: 100
});


export default Table

const styles = {
    container: {
        marginTop: "20px"
    },
    filterForm: {
        margin: "20px"
    }
}