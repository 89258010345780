const HOST = 'https://discovery-ui.mysterium.network/api/v3/proposals'
export async function Proposals(filter) {
    try {
        filter.access_policy = 'all'
        filter.include_monitoring_failed = 'true'
        const query = Object.keys(filter).map(function(key) {
            return key + '=' + filter[key];
        }).join('&')
        const response = await fetch(HOST + "?" + query)
        if (response.status !== 200) {
            console.error(response.status, response.body)
            return
        }
        const jsonResponse =  await response.json()
        let proposals = {}
        jsonResponse.forEach((proposal) => {
            if (!proposals[proposal.provider_id]) {
                proposal.services = [proposal.service_type]
                proposals[proposal.provider_id] = proposal
            } else {
                let oldProposal = proposals[proposal.provider_id]
                let services = oldProposal.services ? oldProposal.services : []
                services.push(proposal.service_type)
                proposal.services = services
                proposals[proposal.provider_id] = proposal
             }
        })
        return Object.values(proposals)
    } catch (e) {
        console.error(e)
    }
}
